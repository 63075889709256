/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Card, CardMedia, CardContent, Typography, Container, CircularProgress, Box, Link } from '@mui/material';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet';

const StyledCardMedia = styled(CardMedia)({
    width: '100%',
    height: 'auto',
    maxHeight: '500px',
    objectFit: 'cover',
});

const StyledCard = styled(Card)({
    maxWidth: '100%',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fffffe',
    borderRadius: '15px',
    margin: '1em auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

const StyledCardContent = styled(CardContent)({
    padding: '16px',
});

const StyledContainer = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: '1em',
    minHeight: 'calc(100vh - 64px)',
});

function SitePost() {
    const { part1, part2, slugName } = useParams();
    const [item, setItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchItem = async () => {
            const composedSlugName = part1 && part2 ? `${part1}/${part2}` : slugName;
            try {
                const response = await axios.post('/.netlify/functions/getSitePostItem', { slugName: composedSlugName });
                setItem(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching item:', error);
                setIsLoading(false);
            }
        };

        fetchItem();
    }, [part1, part2, slugName]);

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (!item) {
        return <p>No item found for this id.</p>;
    }

    return (
        <StyledContainer maxWidth="md">
            <Helmet>
                <title>{item.title ? `${item.title} - Tiny Portal` : 'Site Post - Tiny Portal'}</title>
                <link rel="canonical"
                      href={`https://tinyportal.org/sitepost/${part1 ? `${part1}/${part2}` : slugName}`}/>
                <meta name="description"
                      content={item.content ? `${item.content.substring(0, 150)}...` : "Explore Site Posts on Tiny Portal."}/>
                <link rel="alternate" hrefLang="en"
                      href={`https://tinyportal.org/sitepost/${part1 ? `${part1}/${part2}` : slugName}`}/>
                <link rel="alternate" hrefLang="x-default"
                      href={`https://tinyportal.org/sitepost/${part1 ? `${part1}/${part2}` : slugName}`}/>
            </Helmet>
            <StyledCard>
                {item.imageUrl && (
                    <StyledCardMedia component="img" image={item.imageUrl} alt={item.title}/>
                )}
                <StyledCardContent>
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#0c79a4',
                            textAlign:'left'
                        }}
                    >
                        {/* Use Link component here */}
                        <Link href={item.siteUrl} target="_blank" rel="noopener noreferrer"
                              style={{
                                  textDecoration: 'none',
                                  color: '#007BFF',
                                  fontWeight:'bold'

                            }}
                        >
                            {item.title}
                        </Link>
                    </Typography>
                    <Typography variant="body2" sx={{color: '#353535', textAlign: 'left'}}>
                        {item.content}
                    </Typography>

                </StyledCardContent>
            </StyledCard>
            <div
                className="markdown-container"
                style={{
                    backgroundColor: '#fff',
                    padding: '15px',
                    borderRadius: '15px',
                    marginBottom: '15px',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    textAlign: 'left',
                    color: '#4b5360',
                }}
                dangerouslySetInnerHTML={{__html: item.htmlContent}}
            />
        </StyledContainer>
    );
}

export default SitePost;
