/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Site from "./Site";

const getSlugName = (url) => {
    const parts = url.split('/');
    return parts[parts.length - 1];
};

const SitePostList = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await fetch('/.netlify/functions/fetchWebsites', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}`);
                }

                const itemData = await response.json();

                const formattedItems = itemData.map(data => ({
                    ...data,
                    slugName: getSlugName(data.siteUrl),
                }));

                setItems(formattedItems);

            } catch (error) {
                console.error('Error fetching website data:', error);
            }
        };

        fetchItems();
    }, []);

    return (
        <div>
            <Grid container spacing={3}>
                {items.map((item) => (
                    <Grid item xs={12} key={item._id}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Site item={item} />
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </div>
    );

};

export default SitePostList;
