/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import NavBar from './components/NavBar';
import { Box } from '@mui/material';
import SiteIndex from "./pages/SiteIndex";
import SitePosts from "./components/Sites/SitePosts";
import SitePost from './components/Sites/SitePost';

function App() {
    const [searchString, setSearchString] = useState('');
    return (
        <Box sx={{ mt: 4 }}>
            <div className="App">
                <Router>
                    <NavBar setSearchString={setSearchString} />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/siteindex" element={<SiteIndex />} />
                        <Route path="/siteposts" element={<SitePosts />} />
                        <Route path="/sitepost/:part1/:part2" element={<SitePost />} />
                        <Route path="/sitepost/:slugName" element={<SitePost />} />
                    </Routes>
                </Router>
            </div>
        </Box>
    );
}

export default App;
