/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Card, CardActionArea, CardContent, CardMedia, Typography, Container, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: '100%',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.03)',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
    },
    height: '100%', // Ensures the card stretches to fill the available space
}));

const StyledCardMedia = styled(CardMedia)({
    paddingTop: '56.25%', // 16:9
});

const CardContentBox = styled(Box)({
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '150px', // Ensures the content box has a minimum height
});

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};

const PostList = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get('/.netlify/functions/fetchWebsites');
                setPosts(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Container maxWidth="xl">
            <Grid container spacing={3} sx={{marginTop:'1px'}}>
                {posts.map((post) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={post._id}>
                        <PostCard post={post} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

const PostCard = ({ post }) => (
    <CardActionArea component="a" href={post.siteUrl} target="_blank" rel="noopener noreferrer">
        <StyledCard>
            <Box sx={{ overflow: 'hidden' }}>
                <StyledCardMedia
                    image={post.imageUrl}
                    title={post.title}
                />
            </Box>
            <CardContentBox>
                <CardContent>
                    <Typography variant="h5" color="text.secondary" sx={{ color: '#333333' }}>
                        {truncateText(post.title, 20)}
                    </Typography>
                    <Typography variant="body2" color="#4b5360" sx={{ textAlign: 'left' }}>
                        {truncateText(post.content, 130)}
                    </Typography>
                </CardContent>
            </CardContentBox>
        </StyledCard>
    </CardActionArea>
);

export default PostList;
