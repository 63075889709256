/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PostList from '../components/PostList';

const Home = () => {
    return (
        <main>
            <PostList />
        </main>
    );
};

export default Home;
