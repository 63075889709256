/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet';
import SitePostList from "./SitePostList";

const SitePosts = () => {
    return (
        <Container maxWidth="md">
            <Helmet>
                <title>Site Posts</title>
                <link rel="canonical" href={`https://tinyportal.org/siteposts`} />
                <meta
                    name="description"
                    content="Tiny Portal - Site Post."
                />
                <link rel="alternate" hrefLang="en" href={`https://tinyportal.org/siteposts`} />
                <link rel="alternate" hrefLang="x-default" href={`https://tinyportal.org/siteposts`} />
                <meta property="og:title" content="Tiny Portal - Site Posts" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://tinyportal.org/siteposts" />
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 0,
                }}
            >
                <SitePostList />
            </Box>
        </Container>
    );
};

export default SitePosts;
