/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Box, Card, CardContent, Link as MuiLink } from '@mui/material';
import { keyframes } from '@mui/system';
import { Container } from '@mui/material';
import { Helmet } from 'react-helmet';

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const hoverAnimation = keyframes`
  0% { color: #353535; }
  100% { color: #15a2fa; }
`;

const SiteIndex = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                const response = await axios.get('/.netlify/functions/fetchWebsites');
                setBlogPosts(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogPosts();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Container maxWidth="md">
            <Helmet>
                <title>Site Index - Tiny Portal</title>
                <link rel="canonical" href="https://tinyportal.org/siteindex" />
                <meta
                    name="description"
                    content="Tiny Portal - Find all the URLs related to various numerical solvers and simulators in one place."
                />
                <link rel="alternate" hrefLang="en" href="https://tinyportal.org/siteindex" />
                <link rel="alternate" hrefLang="x-default" href="https://tinyportal.org/siteindex" />
                <meta property="og:title" content="Tiny Portal - Site Index" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://tinyportal.org/siteindex" />
            </Helmet>

            <Box sx={{ marginTop: '0px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
                    <Card
                        sx={{
                            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                            backgroundColor: '#ffffff',
                            borderRadius: '15px',
                            border: '2px solid #35353500',
                            width: '100%',
                            mt: 2,
                        }}
                    >
                        <CardContent sx={{ padding: '14px 0px' }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 'bold',
                                    letterSpacing: '0.05em',
                                    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    animation: `${rainbowAnimation} 10s ease infinite`,
                                    backgroundSize: '200% 200%',
                                    '@media (max-width:600px)': { fontSize: '1.2em' },
                                    marginBottom: '10px',
                                }}
                            >
                                Site Index
                            </Typography>
                            <Box sx={{ textAlign: 'left', margin: '0 30px', maxWidth: 'false' }}>
                                {blogPosts.map((post, index) => (
                                    <MuiLink
                                        key={index}
                                        href={post.siteUrl} // Ensure we are using post.siteUrl
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 1,
                                            color: '#353535',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                color: '#15a2fa',
                                            },
                                        }}
                                    >
                                        <i className="fas fa-angle-right" style={{ marginRight: '10px', color: '#15a2fa' }}></i>
                                        <Typography variant="body1">{post.title}</Typography>
                                    </MuiLink>
                                ))}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Container>
    );
};

export default SiteIndex;
