/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    IconButton,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { keyframes } from '@emotion/react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';
import './NavBar.css';
import { FaSitemap } from "react-icons/fa";
import { PiArticleMediumBold } from "react-icons/pi";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const drawerWidth = 240;

const NavBar = ({ setSearchString }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [localSearchString, setLocalSearchString] = useState('');

    useEffect(() => {
        setSearchString(localSearchString);
    }, [localSearchString]);

    const handleSearchChange = (event) => {
        setLocalSearchString(event.target.value);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const drawerItems = [
        {
            text: 'Home',
            icon: <HomeIcon className="nav-item" />,
            route: '/',
        },
        {
            text: 'Index',
            icon: <FaSitemap className="nav-item" style={{ fontSize: '20px' }} />,
            route: '/siteindex',
        },
        {
            text: 'Posts',
            icon: <PiArticleMediumBold className="nav-item" style={{ fontSize: '20px' }} />,
            route: '/siteposts',
        },
        // Add more items as needed
        ...(isAuthenticated
            ? [
                // {
                //     text: 'Account',
                //     icon: <CardMembershipIcon className="nav-item" />,
                //     route: '/subscribe',
                // },
            ]
            : []),
    ];

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: '#ffffff',
                    padding: '3px',
                    borderBottom: '2px solid #35353500'
                }}
            >
                <Toolbar>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                        <div className="nav-row nav-row-logo">
                            <NavLink to="/" className="nav-logo nav-item">
                                Tiny Portal
                            </NavLink>
                            <div onClick={() => setOpen(!open)} className="nav-icon">
                                {open ? <FiX /> : <FiMenu />}
                            </div>
                        </div>
                    </Box>
                </Toolbar>
                <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle} sx={{ mr: 2, flexShrink: 0 }}>
                        <MenuIcon style={{ color: '#353535' }} />
                    </IconButton>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                        <Button startIcon={<HomeIcon />} component={RouterLink} to="/" className="nav-item" sx={{ color: '#353535' }}>Home</Button>
                        <Button startIcon={<FaSitemap />} component={RouterLink} to="/siteindex" className="nav-item" sx={{ color: '#353535' }}>Index</Button>
                        <Button startIcon={<PiArticleMediumBold />} component={RouterLink} to="/siteposts" className="nav-item" sx={{ color: '#353535' }}>Posts</Button>
                    </Box>
                    <Box sx={{ flexShrink: 0, width: '48px' }}> {/* Placeholder to balance the IconButton width */}</Box>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="persistent"
                open={drawerOpen}
                sx={{
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                        top: '128px', // AppBar height on desktop devices
                        height: 'calc(100% - 128px)',
                        backgroundColor: '#ffffff',
                    },
                }}
            >
                <List>
                    {drawerItems.map((item, index) => (
                        <ListItem button key={index} component={RouterLink} to={item.route}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} className="nav-item" />
                        </ListItem>
                    ))}
                </List>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, pt: '120px', ml: { md: drawerWidth } }}>
                {/* Main content */}
            </Box>
        </>
    );
};

export default NavBar;
