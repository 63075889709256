/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
    display: 'block',
});

const getSlugName = (url) => {
    const parts = url.split('/');
    if (parts.includes('achievement') && !isNaN(parts[parts.length - 1])) {
        return `${parts[parts.length - 2]}/${parts[parts.length - 1]}`;
    }
    return parts[parts.length - 1];
};

const Site = ({ item }) => {
    const slugName = getSlugName(item.siteUrl);

    return (
        <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            to={`/sitepost/${slugName}`}
        >
            <Card
                elevation={0}
                sx={{
                    maxWidth: '100%',
                    backgroundColor: '#fffffe',
                    transition: 'transform .2s',
                    '&:hover': {
                        transform: 'scale(1.02)',
                    },
                }}
            >
                <CardContent>
                    <Typography
                        variant="body1"
                        sx={{
                            color: 'black',
                            marginBottom: '8px',
                            textAlign: 'left',
                        }}
                    >
                        {item.title}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            marginBottom: '16px',
                            color: '#353535',
                            textAlign: 'left',
                        }}
                    >
                        {item.content}
                    </Typography>
                </CardContent>
            </Card>
        </StyledLink>
    );
};

export default Site;
